<template>
  <div>
    <div class="contents">
      <div class="tableSearch">
          <div class="classObj"><el-button @click="shouquan" type="primary" icon="el-icon-plus">创建渠道群</el-button></div>
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="80px">
             <el-form-item label="" style="margin-bottom:0px">
              <el-input v-model="searchForm.name" clearable  style="width:190px"
                placeholder="请输入渠道名称"></el-input>
            </el-form-item>             
            <el-button @click="getTopSearch" type="primary" icon="el-icon-search">查询</el-button>
          </el-form>
      </div>

    <div class="mainbox">

        <el-button class="btns" type="primary" plain icon="el-icon-plus" @click="handleDisableFileNames(0)">批量添加禁收文件关键词</el-button>

        <el-button class="btns" type="primary" plain icon="el-icon-minus" @click="handleDisableFileNames(1)">批量移除禁收文件关键词</el-button>

       <el-table
        :data="tableData"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"        
        >
        <el-table-column
          type="selection"
          width="40" header-align="center" align="center">
        </el-table-column>
        <el-table-column
          type="index"
          width="40" header-align="center" align="center">
        </el-table-column>
        <el-table-column
          prop="name"
          label="渠道名称"
          width="180">
        </el-table-column>
         <el-table-column
          prop="robotName"
          label="所属机器人"
          width="100">
        </el-table-column>      
        <el-table-column
          prop="groupName"
          label="群名称"
          width="180">
        </el-table-column>
        <el-table-column
          label="机器人" width="100"  header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.valid===1" type="success">已启用</el-tag>
            <el-tag v-if="scope.row.valid===0" type="danger">已停用</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          label="自动收单" width="100"  header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.autoReceive===1" type="success">已启用</el-tag>
            <el-tag v-if="scope.row.autoReceive===0" type="danger">已停用</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          label="自动返单" width="100"  header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.autoReturn===1" type="success">已启用</el-tag>
            <el-tag v-if="scope.row.autoReturn===0" type="danger">已停用</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          label="返单规则"
          width="90">
          <template slot-scope="scope">
            <span v-if="scope.row.autoReturnRules.type == 'immediately'">即时返单</span>
            <span v-if="scope.row.autoReturnRules.type == 'cronjob'">定时返单</span>
           </template>
        </el-table-column>

        <el-table-column
          label="定时返单时间"
          width="100">
          <template slot-scope="scope">
             <li class="li" :key="item"
             v-for="item in scope.row.deadlines">{{ item }}</li>
           </template>
        </el-table-column>

         <el-table-column
          prop="remark"
          label="备注">
        </el-table-column>
       <el-table-column
      fixed="right"
      label="操作"
      width="120" header-align="center" align="center">
      <template slot-scope="scope">
        <!-- <el-button @click="yaoFun(scope.row)" type="text" size="small">邀请</el-button> -->
        <el-button @click="editFun(scope.row)" type="text" size="small">编辑</el-button>
        <el-popconfirm @confirm="delChannel(scope.row)"
          title="确定要删除此渠道群吗，删除后将无法收单返单？"
          style="margin-left: 10px;"
        >
        <template #reference>
          <el-button type="text">删除</el-button>
          </template>
       </el-popconfirm>
      </template>
    </el-table-column>
      </el-table>
      <div class="paginations">
          <el-pagination
          background
          layout="total, prev, pager, next, sizes"
          @current-change="changePage" :current-page="page" :page-size="pageSize"
          :page-sizes="[20, 40, 50, 100]"
          :total="total">
        </el-pagination>
      </div>
      </div>
    </div>

    <el-dialog title="我要邀请渠道/团长" :visible.sync="yaoflag" :before-close="downClose" width="660px">
        <div class="fenxiaoBox">
          <el-alert
            title="点击邀请按钮，将发送一个邀请链接到群里，请您的合作伙伴一起来使用开团易，他也能看到订单哦"
            type="success"
            :closable="false">
          </el-alert>
        
            <div class="txtpp">
             <el-select v-model="qunvalue" placeholder="请选择渠道/团长">
            <el-option
              v-for="item in qunoptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
            </div>
        </div>
        <div class="midCss" style="padding-top:20px">
          <el-button type="primary" @click="handleSubmit">确认邀请</el-button>
          <el-button @click="downClose">取 消</el-button>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import { listRetailerGroups, groupMember,deleteGroups, inviteRetailerFun, batchSetKeywords } from "@/api/robot";
import {
  MessageBox
} from 'element-ui'
export default {
  name: 'Channel',
  data() {
    return {
      searchForm: {
        name: '',
        groupName: ''
      },
      total: 0,
      page: 1,
      pageSize: 20,
      shopflag: false,
      qunvalue: '',
      qunid: '', 
      shopArr: [],
      qunoptions: [],
      loadingFile: false,
      activeName: 'first',
      yaoflag: false,
      tableData: [],
      multipleSelection: []
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleDisableFileNames(operation) {
      if(this.multipleSelection.length == 0) {
        this.$message.warning("请先选择群");
        return;
      }
      this.$prompt('请输入关键词，多个关键词可以用,分隔', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
      }).then(({ value }) => {
          const param = {
            operation: operation,
            keywords: value,
            ids: this.multipleSelection.map(it => it.id)
          }
          batchSetKeywords(param).then(res => {
            if(res.code === 200) {
              this.$message.success(res.message)
            } else {
              this.$message.error(res.message)
            }
          })
      }).catch(() => {
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleClose(){
      this.shopflag = false;
    },
    getTopSearch(e){
      var that = this
      var datas = that.searchForm
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.orderByCreated = "DESC";
      listRetailerGroups(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.tableData = res.data.rows
          that.total = res.data.totalRows
        }else{
          this.$message.error(res.message)
        }
      })
    },
    getPlat(e){
      var that = this
      getPlatforms().then(res => {
        console.log(res);
        if(res.code ===200) {
          that.shopArr = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
    editFun(row){
      var that = this
      that.$router.push({
        path: "/channel/detail?id=" + row.id,
      })
    },
    yaoFun(row){
      var that = this
      that.yaoflag = true
      var datas = {
        botId: row.botId,
        groupId: row.groupId
      }
      that.qunid = row.id
      groupMember(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.qunoptions = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    downClose(){
      var that = this
      that.yaoflag = false
    },
    handleSubmit(){
      var that = this
      console.log(that.qunvalue)
      if(!that.qunvalue){
        that.$message({
          message: '请先选择渠道/团长',
          type: 'error'
        })
        return
      }
      var qunobjs = {}
      var datas = {}
      datas.id = that.qunid 
      that.qunoptions.map((item)=>{
        if(item.id==that.qunvalue){
          qunobjs= item
        }
      })
      MessageBox.alert('请确认是否选择【'+qunobjs.name +'】为您的渠道或团长吗？', '温馨提示', {
        confirmButtonText: '确认',
        type: 'warning'
      }).then(() => { 
        datas.member = qunobjs
        inviteRetailerFun(datas).then((res) => {
          if(res.code == 200){
            that.$message({
              message: '邀请成功',
              type: 'success'
            })
            that.yaoflag = false
          }else{
            this.$message.error(res.message)
          }
        })
      })
    },
    delChannel(row){
       var that = this
       var datas = {
         id: row.id,
       }
       deleteGroups(datas).then(res => {
         if(res.code === 200) {
            that.$message({
              message: '删除成功',
              type: 'success'
            })
            that.getTopSearch()
         }else{
           this.$message.error(res.message)
         }
       })
    },
    shouquan(){
      var that = this
      that.$router.push({
        path: "/channel/detail"
      })
    }
  },
  mounted(){
    this.getTopSearch()
  }
}
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}
/* .el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
} */
.classObj{
  float:right;
  font-size: 12px;
  color: #999;
  cursor: pointer;
}
.shopLogo{
  display: block;
  overflow: hidden;
}
.shopLogo ol{
  float:left;
  width: 100px;
  border: 1px solid #eee;
  padding: 5px;
  margin: 0;
  margin-right: 10px;
  cursor: pointer;
}
.shopLogo ol img{
  width: 100%;display: block;
}
.txtpp{
  padding: 40px;
  display: block;
  overflow: hidden;
  text-align: center;
}
.li {
  padding-left: 1em; 
  text-indent: -.7em;
  list-style: none;
}

.li::before {
  content: "• ";
  font-size: 1.5em;
  color: #07C160; /* or whatever color you prefer */
}
.btns {
  margin:0px 10px 10px 0px;
}
</style>